<template>
  <div class="m-md gap-sm laptop:grid-cols-3 grid auto-rows-fr grid-cols-1">
    <div
      v-for="(website, i) in websites"
      :key="`website-${i}`"
      class="gap-md p-md relative grid grid-rows-3 justify-center text-center shadow-md hover:bg-blue-100 hover:shadow-lg active:bg-blue-50"
      :class="{
        'laptop:col-start-2': i === websites.length - 1,
      }"
    >
      <NuxtLink
        class="row-span-2 after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0"
        :to="{
          path: website.url,
          query: authStore.isConnected
            ? {
                access_token: authStore.apiToken.access,
                access_expire: authStore.accessExpUnix,
                refresh_token: authStore.refreshToken.access,
                refresh_expire: authStore.refreshExpUnix,
              }
            : null,
        }"
        external
      >
        <img :src="website.image" class="mx-auto" alt="" />
        <span class="text-large">
          {{ website.name }}
        </span>
      </NuxtLink>

      <div class="gap-xs flex justify-center">
        <NuxtLink
          v-if="website.google"
          class="p-xxs z-10 flex items-center"
          :to="website.google.url"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            width="135"
            height="40"
            src="~/assets/images/google-play.png"
            :alt="`${website.google.alt} - ouvre une nouvelle fenêtre`"
          />
        </NuxtLink>
        <NuxtLink
          v-if="website.huawei"
          class="p-xxs z-10 flex items-center"
          :to="website.huawei.url"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            width="135"
            height="40"
            src="~/assets/images/huawei-store.png"
            :alt="`${website.huawei.alt} - ouvre une nouvelle fenêtre`"
          />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup>
import grenier from "~/assets/images/logos/grenier.png";
import maisons from "~/assets/images/logos/maisons.png";
import loto from "~/assets/images/logos/loto.png";
import noel from "~/assets/images/logos/noel.png";
import dressing from "~/assets/images/logos/dressing.png";
import vetements from "~/assets/images/logos/vetements.png";
import puericulture from "~/assets/images/logos/puericulture.png";
import jouets from "~/assets/images/logos/jouets.png";
import populaire from "~/assets/images/logos/populaire.png";
import solidaire from "~/assets/images/logos/solidaire.png";

definePageMeta({
  header: "home",
  footer: "default",
});

// Data -------------------------------------------------------------------------------------------

const authStore = useAuthStore();

const websites = [
  {
    image: grenier,
    name: "Agenda des vide-greniers et brocantes",
    url: import.meta.env.VITE_URL_GRENIER,
    huawei: {
      url: "https://appgallery.huawei.com/#/app/C101799543",
      alt: "Télécharger l'application vide-greniers.org sur l'AppGallery",
    },
    google: {
      url: "https://play.google.com/store/apps/details?id=com.sopheos.videgreniersmobile",
      alt: "Télécharger l'application vide-greniers.org sur Android",
    },
  },
  {
    image: maisons,
    name: "Agenda des vide-maisons",
    url: import.meta.env.VITE_URL_MAISONS,
    google: {
      url: "https://play.google.com/store/apps/details?id=com.sopheos.videmaisons",
      alt: "Télécharger l'application Vide-maisons sur Android",
    },
  },
  {
    image: loto,
    name: "Agenda des lotos traditionnels",
    url: import.meta.env.VITE_URL_LOTO,
    google: {
      url: "https://play.google.com/store/apps/details?id=fr.sopheos.loto",
      alt: "Télécharger l'application Agenda des lotos sur Android",
    },
  },
  {
    image: noel,
    name: "Agenda des marchés de Noël",
    url: import.meta.env.VITE_URL_NOEL,
    google: {
      url: "https://play.google.com/store/apps/details?id=fr.sopheos.marchesdenoel",
      alt: "Télécharger l'application Agenda des marchés de Noël sur Android",
    },
  },
  {
    image: dressing,
    name: "Agenda des vide-dressings",
    url: import.meta.env.VITE_URL_DRESSING,
  },
  {
    image: vetements,
    name: "Agenda des bourses aux vêtements",
    url: import.meta.env.VITE_URL_VETEMENTS,
  },
  {
    image: puericulture,
    name: "Agenda des bourses puériculture et de la petite enfance",
    url: import.meta.env.VITE_URL_PUERICULTURE,
  },
  {
    image: jouets,
    name: "Agenda des bourses aux jouets",
    url: import.meta.env.VITE_URL_JOUETS,
  },
  {
    image: populaire,
    name: "Agenda des marches populaires",
    url: import.meta.env.VITE_URL_POPULAIRE,
  },
  {
    image: solidaire,
    name: "Agenda des ventes solidaires",
    url: import.meta.env.VITE_URL_SOLIDAIRE,
  },
];
</script>
